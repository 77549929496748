<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>list_alt</md-icon>
            <h3 class="pl-2 text-uppercase">Dummy Demo Coaching Center</h3>
          </div>
          <md-button
              class="bg-green text-white rounded-md md-dense m-0 text-uppercase"
              :to="{ name: 'master.dummyCochingCreate' }"
          >
            <md-icon class="text-white">add</md-icon> Create New Dummy Demo Coaching Center
          </md-button>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-md-center justify-sm-center justify-xs-center">
          <!-- <SearchBox class="w-64 p-2 m-2" /> -->
        </div>
        <md-divider></md-divider>

        <Table :items="organizationList">
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-sort-by="id" md-label="SL">{{item.sl}}</md-table-cell>
            <md-table-cell md-label="Name">{{ item.business_name }}</md-table-cell>
            <md-table-cell md-label="Email">{{ item.business_email }}</md-table-cell>
            <md-table-cell md-label="Contact Number">{{ item.business_contact_number }}</md-table-cell>
            <md-table-cell md-label="Package Type">{{ item.package_type }}</md-table-cell>
            <md-table-cell md-label="Payment Type">{{ item.payment_type }}</md-table-cell>
            <md-table-cell md-label="Created At">{{ item.created_at }}</md-table-cell>
            <md-table-cell>
              <md-icon class="bg-default rounded-full p-1 text-gray-700 text-base mr-1"  @click.native="onShowDetails(item.id)">visibility</md-icon>
              <md-icon class="bg-danger rounded-full p-1 text-white text-base mx-1" @click.native="deleteOrganization(item.id)">delete</md-icon>
            </md-table-cell>
          </template>
        </Table>
        <div class="flex justify-content-center align-center mt-8">
          <Paginate
              :start="meta.current_page"
              :end="meta.last_page"
              :total="meta.last_page"
              :limit="meta.per_page"
              @on-start="onStart"
              @on-end="onEnd"
          />
        </div>

      </CardBody>

      <Dialog >
        <components :content="content" :is="component"></components>
      </Dialog>
      <ConfirmDialog 
        @confirm="actDeleteOrganization(organizationId)"
        @success="deleteSuccess"
        :active.sync="active"
      />
    </Card>
  </div>
</template>

<script>
import { Card, CardBody, Table, Dialog, Paginate, ConfirmDialog } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import ShowCoachingCenterDetails from "@/components/molecule/coaching-center/ShowCoachingCenterDetails";
import data from "@/data/form/data";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { query } from "@/utils/queryString";

export default {
  name: "Form-List-View",
  components: {
    Card,
    CardBody,
    SearchBox,
    Table,
    Dialog,
    ShowCoachingCenterDetails,
    Paginate,
    ConfirmDialog
  },
  data() {
    return {
      active: false,
      organizationId: '',
      query: '?',
      currentIndex: 0,
      coachingCenter: [],
      forms: data.forms,
      content: {},
      component: '',
    };
  },
   computed: {
      ...mapGetters({
        organizationList: "dummyOrganization/organizationList",
        meta: "dummyOrganization/organizationMeta",
      }),
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
      ...mapActions({
      actGetOrganizationList: 'dummyOrganization/actGetOrganizationList',
      actGetOrganizationByOrganizationId: "dummyOrganization/actGetOrganizationByOrganizationId",
      actDeleteOrganization: "dummyOrganization/actDeleteOrganization",
    }),
    deleteSuccess(){
      this.actGetOrganizationList(`?search=COACHING_CENTER&${query.get()}`);
      this.setShowSnackbar('Organization deleted successfully !');
    },
    deleteOrganization(organizationId) {
      this.active = true;
      this.organizationId = organizationId;
    },
    onShowDetails(id) {

      this.component = 'ShowCoachingCenterDetails';
      this.actGetOrganizationByOrganizationId(id)
      .then( ({items}) => {
          this.content = items;
          this.dialog(true);
      })
      .catch(error => console.log(error))
    },

    onStart(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actGetOrganizationList(`?search=COACHING_CENTER&${query.get()}`);
    },
    onEnd(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actGetOrganizationList(`?search=COACHING_CENTER&${query.get()}`);
    },
  },

  created(){
      this.actGetOrganizationList(`?search=COACHING_CENTER`);
  }
};
</script>
<template>
  <div class="dashboard-body" style="height: 100vh;">
    <WelcomeCard label="portal.welcome" />
    <div class="md-layout mt-8 gap-4 flex-start">
      <Spinner :loading="loading" :diameter="40" :stroke="3" />
      <div class="md-layout-item md-small-size-100">
        <div class="md-layout gap-4">
          <div class="md-layout-item md-size-48 md-small-size-100">
            <md-card-content class="d-bg-black text-white relative overflow-hidden rounded-xl">
              <div class="flex items-center text-left">
                <div class="w-full">
                  <div class="flex items-center justify-between relative z-index-999 mb-2">
                    <div class="rounded-full p-2 bg-lite-algal">
                      <md-icon class="text-dodger-blue">diversity_1</md-icon>
                    </div>
                    <div class="rounded-full p-2 bg-lite-algal">
                      <md-icon class="text-dodger-blue">more_horiz</md-icon>
                    </div>
                  </div>
                  <div class="student-card dashboard-card flex">
                    <div>
                      <p class="my-5 text-5xl">{{getDashboardInfo.coaching ?? 0}}</p>
                    </div>
                    <div>
                      <md-avatar class="md-avatar-icon">
                        <md-icon>arrow_upward</md-icon>
                      </md-avatar>
                    </div>
                  </div>
                  <p class="my-2 text-lg opacity-50">Total Coaching</p>
                </div>
              </div>
            </md-card-content>
          </div>
          <div class="md-layout-item md-size-48 md-small-size-100">
            <md-card-content class="d-bg-admin text-white relative overflow-hidden rounded-xl">
              <div class="flex items-center text-left">
                <div class="w-full">
                  <div class="flex items-center justify-between relative z-index-999 mb-2">
                    <div class="rounded-full p-2 bg-lite-algal">
                      <md-icon class="text-dodger-blue">diversity_1</md-icon>
                    </div>
                    <div class="rounded-full p-2 bg-lite-algal">
                      <md-icon class="text-dodger-blue">more_horiz</md-icon>
                    </div>
                  </div>
                  <div class="student-card dashboard-card flex">
                    <div>
                      <p class="my-5 text-5xl">{{getDashboardInfo.trainer ?? 0}}</p>
                    </div>
                    <div>
                      <md-avatar class="md-avatar-icon">
                        <md-icon>arrow_upward</md-icon>
                      </md-avatar>
                    </div>
                  </div>
                  <p class="my-2 text-lg opacity-50">Total Trainer</p>
                </div>
              </div>
            </md-card-content>
          </div>
          <div class="md-layout-item md-size-48 md-small-size-100">
            <md-card-content class="d-bg-green text-white relative overflow-hidden rounded-xl">
              <div class=" flex items-center text-left">
                <div class="w-full">
                  <div class="flex items-center justify-between relative z-index-999 mb-2">
                    <div class="rounded-full p-2 bg-lite-algal">
                      <md-icon class="text-greenish-teal">diversity_3</md-icon>
                    </div>
                    <div class="rounded-full p-2 bg-lite-algal">
                      <md-icon class="text-greenish-teal">more_horiz</md-icon>
                    </div>
                  </div>
                  <div class="coaching-card dashboard-card flex">
                    <div>
                      <p class="my-5 text-5xl">{{getDashboardInfo.student ?? 0}}</p>
                    </div>
                    <div>
                      <md-avatar class="md-avatar-icon">
                        <md-icon>arrow_upward</md-icon>
                      </md-avatar>
                    </div>
                  </div>
                  <p class="my-2 text-lg opacity-50">Total Student</p>
                </div>
              </div>
            </md-card-content>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-size-30 md-small-size-100">
        <div class="md-layout gap-4">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-card-content class="d-bg-purple text-white relative overflow-hidden rounded-xl">
              <div class="height-auto flex items-center text-left">
                <div class="bg-lite-algal p-3 mr-4 rounded-lg">
                  <md-icon class="text-3xl text-black">help_outline</md-icon>
                </div>
                <div class="w-full">
                  <div class="question-card dashboard-card flex items-center">
                    <div>
                      <p class="my-1 text-3xl">{{getDashboardInfo.questions ?? 0}}</p>
                    </div>
                  </div>
                  <p class="my-0 text-base opacity-50">Total Question</p>
                </div>
              </div>
            </md-card-content>
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-card-content class="d-bg-trans-blue text-white relative overflow-hidden rounded-xl">
              <div class="height-auto flex items-center text-left">
                <div class="bg-lite-algal p-3 mr-4 rounded-lg">
                  <md-icon class="text-3xl text-black">quiz</md-icon>
                </div>
                <div class="w-full">
                  <div class="quiz-card dashboard-card flex items-center">
                    <div>
                      <p class="my-1 text-3xl">{{getDashboardInfo.quizes ?? 0}}</p>
                    </div>
                  </div>
                  <p class="my-0 text-base opacity-50">Total Quiz</p>
                </div>
              </div>
            </md-card-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { WelcomeCard, Spinner } from "@/components";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    WelcomeCard,
    Spinner
  },
  data() {
    return {
      content: {},
      date: new Date('29 Oct, 2020'),
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      getDashboardInfo: "dashboard/getDashboardInfo",
    }),
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    ...mapActions({
      actGetDashboard: "dashboard/actGetDashboard",
    }),
    fetchDashboard () {
      this.loading = true
      this.actGetDashboard().then(() => {
        this.loading = false
      }).catch(error => {
          this.setShowSnackbar(error.response.data.message);
          this.loading = false;
      });
    }
  },
  mounted() {
    this.fetchDashboard()
  },
};
</script>

<style scoped>
.flex-start {
  align-items: flex-start;
}
.md-card-content:last-of-type {
  padding-bottom: 14px;
}
.border-bottom {
  border-bottom: 1px solid #ddd;
}
</style>